import { useReducer, createContext, PropsWithChildren } from 'react';
import AppReducer, { AppStateProps, AppActionsProps, loadState } from './reducer';

const initialState: AppStateProps = {
  availableProviders: [],
  selectedProvider: 'All',
  selectedAccount: {
    accountID: undefined,
    accountAlias: undefined,
    accountRegion: undefined,
    accountProvider: undefined,
    accountLastUpdatedTime: undefined,
  },
  isGraphFullscreen: false,
  isRiskFocusViewActive: true,
  isGraphLoading: false,
};

const AppStore = (props: PropsWithChildren) => {
  const [state, dispatchGlobalStoreEvent] = useReducer(AppReducer, loadState() || initialState);
  return <AppContext.Provider value={[state, dispatchGlobalStoreEvent]} {...props} />;
};

export const AppContext = createContext<[AppStateProps, React.Dispatch<AppActionsProps>]>(
  undefined as never
);

export default AppStore;
