import { useMemo } from 'react';
import { Box, Stack, Text, Flex, Skeleton } from '@tonic-ui/react';
import { useTranslation } from 'react-i18next';
import LineChart from './LineChart';
import { formatDateTime } from '../../../../utils/time';
import { getRiskColor } from '../../../helpers';
import { CloudAssetProfileData } from '../CloudAssetsDetailsDrawer';

type BasicInfoProps = {
  cloudAssetProfileData: CloudAssetProfileData;
  riskIndexLineData: any;
  riskHistoryIsLoading: boolean;
};

const BasicInfo = (props: BasicInfoProps) => {
  const { cloudAssetProfileData, riskIndexLineData, riskHistoryIsLoading } = props;
  const { t } = useTranslation();

  const latestRiskScore = cloudAssetProfileData?.latestRiskScore;
  const riskColor = getRiskColor(latestRiskScore);

  const summaryProfile = useMemo(() => {
    const keys = [
      { name: 'Resource Type' },
      { name: 'Provider' },
      { name: 'Region' },
      { name: 'Last seen' },
    ] as const;

    const profile = cloudAssetProfileData?.assetProfile || {};
    profile['Last seen'] = formatDateTime(
      (cloudAssetProfileData?.data?.lastDetectedTime ?? 0) * 1000
    );

    return (
      <Stack color="white:secondary">
        {keys.map(({ name }) => {
          return (
            <Box key={name}>
              <Text>
                {t(`riskGraph.assetsDetailsDrawer.${name}`)}: {profile[name] || '-'}
              </Text>
            </Box>
          );
        })}
      </Stack>
    );
  }, [cloudAssetProfileData, t]);

  return (
    <Box marginBottom="4x">
      <Text fontSize="md" lineHeight="md" paddingTop="4x" paddingBottom="2x">
        {t('riskGraph.assetsDetailsDrawer.summary')}
      </Text>
      <Text fontSize="sm" lineHeight="sm">
        {summaryProfile}
      </Text>
      <Flex marginTop="4x">
        <Box flexShrink="0">
          <Flex alignItems="flex-end" marginTop="1x" display="inline">
            <Text fontSize="42px" lineHeight="52px" color={riskColor}>
              {latestRiskScore ?? 0}
            </Text>
          </Flex>
          <Box marginTop="2x" fontSize="sm" lineHeight="sm" color="white:tertiary">
            {t('riskGraph.assetsDetailsDrawer.latestRiskScore')}
          </Box>
        </Box>
        <Box flexGrow="1">
          {riskHistoryIsLoading ? (
            <Box marginX="2x">
              {/* @ts-ignore */}
              <Skeleton variant="rectangle" animation="wave" width="100%" height="88px" />
            </Box>
          ) : (
            <LineChart data={riskIndexLineData.data.data} />
          )}
        </Box>
      </Flex>
    </Box>
  );
};

BasicInfo.displayName = 'BasicInfo';
export default BasicInfo;
