// NOTE: This is a simplified version of the previous code that only supports the `YYYY-MM-DD HH:mm` format. Add more formats as needed
const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';
// https://day.js.org/docs/en/display/format
const dayjs = window.parent?.APP?.$dayjs;

const getTimeZoneIdentifier = (): UICConsoleSettings => {
  let result = {
    timezone_identifier: 'UTC',
  };

  try {
    result = JSON.parse(window.localStorage.getItem('uic_consoleSettings') || '{}');
  } catch (e) {
    console.warn('Error parsing uic_consoleSettings', e);
  }

  return result;
};

export const formatDateTime = (
  dateTime?: string | number | Date,
  format = DATE_TIME_FORMAT
): string | undefined => {
  if (!dateTime) return;

  const { timezone_identifier } = getTimeZoneIdentifier();

  // NOTE: Use dayjs from parent window if available
  if (dayjs && typeof dayjs === 'function') {
    return dayjs(dateTime).tz(timezone_identifier).format(format);
  }

  // else use JS date formatting as a fallback
  return new Date(dateTime)
    .toLocaleString('us', {
      timeZone: timezone_identifier,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    })
    .replace(',', '');
};
