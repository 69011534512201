import { Flex, Text, Tooltip, Spinner } from '@tonic-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { InfoOIcon } from '@tonic-ui/react-icons';
import { useContext } from 'react';
import { AppContext } from '../store/store';
import { formatDateTime } from '../utils/time';

const LastUpdated = () => {
  const { t } = useTranslation();
  const [{ isGraphLoading, selectedAccount }] = useContext(AppContext);

  const lastUpdatedTime = formatDateTime(selectedAccount?.accountLastUpdatedTime);

  return (
    <Flex
      width="auto"
      backgroundColor="gray:90"
      padding="2x"
      alignItems="center"
      justifyContent="space-between"
    >
      <Text color="white:primary" paddingRight="2x">
        <Trans i18nKey="lastUpdated" />: {lastUpdatedTime ?? t('notAvailable')}
      </Text>
      {isGraphLoading ? (
        <Spinner size="xs" />
      ) : (
        <Tooltip
          label={<Trans i18nKey="riskGraph.lastUpdatedTimeTooltip" />}
          placement="bottom"
          backgroundColor="gray:80"
          color="white:primary"
        >
          <InfoOIcon color="white:secondary" />
        </Tooltip>
      )}
    </Flex>
  );
};

export default LastUpdated;
