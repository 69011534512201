import azureProviderRegions from './azure-regions.json';

interface ProviderRegion {
  name: string;
  displayName: string;
}

const allProviderRegions = [...azureProviderRegions];

const getProviderRegions = (provider: string | undefined): ProviderRegion[] => {
  switch (provider) {
    case 'All':
      return allProviderRegions;
    case 'Azure':
      return azureProviderRegions;
    default:
      return [];
  }
};

export const regionNameMapping = (provider: string | undefined, region = ''): string => {
  const matchedRegion = getProviderRegions(provider)?.find(
    (element: ProviderRegion) => element.name === region
  );
  return matchedRegion ? matchedRegion.displayName : region;
};
