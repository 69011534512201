import { Text, Flex } from '@tonic-ui/react';
import { Trans } from 'react-i18next';

const NodeTooltipNumberedItem = (props: {
  labelTransKey: string;
  value: number | string;
  valueTextColor?: string;
}) => {
  const { labelTransKey, value, valueTextColor = 'white:emphasis' } = props;

  return (
    <Flex width="100%" alignItems="center" justifyContent="space-between">
      <Trans i18nKey={labelTransKey} />
      <Text paddingLeft="2x" color={valueTextColor}>
        {value}
      </Text>
    </Flex>
  );
};

NodeTooltipNumberedItem.displayName = 'NodeTooltipNumberedItem';
export default NodeTooltipNumberedItem;
