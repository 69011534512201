import { securityGroupServiceTypes } from '../helpers';
import { Flags } from '../../utils/feature-flags';

const groupNodePadding = 20;
const groupLabelHeight = 20;
const textPadding = 50;
const itemsPerColumn = 3;

export const nodeLabelTextHeight = 20;
export const graphBGColorCode = 'gray:100';
export const tooltipBGColorCode = 'gray:80';

export const getGraphConstants = (nodeRadius: number): any => {
  const nodeLabelTextWidth = nodeRadius * 2 + textPadding;
  const nodeLabelTextPadding = (nodeLabelTextWidth + textPadding - nodeRadius * 2) / 2;
  const nodeWidth = nodeLabelTextWidth + nodeLabelTextPadding;
  const nodeHeight = nodeRadius * 2 + nodeLabelTextHeight + 40;

  return {
    itemsPerColumn: itemsPerColumn,
    nodeWidth: nodeWidth,
    nodeHeight: nodeHeight,
    nodeLabelTextWidth: nodeLabelTextWidth,
    nodeLabelTextHeight: nodeLabelTextHeight,
    nodeLabelTextPadding: nodeLabelTextPadding,
    groupNodePadding: groupNodePadding,
  };
};

export const getGroupNodeWidth = (nodeRadius: number, childrenCount: number): number => {
  const nodeColumnCount = Math.ceil(childrenCount / itemsPerColumn);
  return groupNodePadding * 2 + nodeColumnCount * getGraphConstants(nodeRadius).nodeWidth;
};

export const getGroupNodeHeight = (nodeRadius: number, childrenCount: number): number => {
  return (
    2 * groupNodePadding +
    (childrenCount < itemsPerColumn
      ? getGraphConstants(nodeRadius).nodeHeight * childrenCount
      : itemsPerColumn * getGraphConstants(nodeRadius).nodeHeight) +
    groupLabelHeight / 2
  );
};

export const flipGridCoordinates = (grid: GraphGrid, gridHeight: number) => {
  const invertedGrid: GraphGrid = [];
  for (let i = 0; i < gridHeight; i++) {
    invertedGrid.push([]);
    grid.forEach((gridCol: any[]) => {
      invertedGrid[i].push(gridCol[i] !== undefined ? gridCol[i] : -1);
    });
  }
  return invertedGrid;
};

// Minimum zoom level means no zoom is applied.
// Maximum zoom level means maximum zoom out is applied, so elements are scaled down in size.
export const MIN_ZOOM_VALUE = 0.05;
export const MAX_ZOOM_VALUE = 1;

export enum ZoomLevel {
  Narrow = 'narrow',
  Medium = 'medium',
  Wide = 'wide',
  VeryWide = 'verywide',
}

interface ZoomLimitsValues {
  min: number;
  max: number;
}

const ZoomLevelValues: Record<ZoomLevel, ZoomLimitsValues> = {
  narrow: {
    min: MIN_ZOOM_VALUE,
    max: 0.45,
  },
  medium: {
    min: 0.45,
    max: 0.22,
  },
  wide: {
    min: 0.22,
    max: 0.15,
  },
  verywide: {
    min: 0.15,
    max: MAX_ZOOM_VALUE,
  },
};

const getZoomLevel = (zoomValue: number): ZoomLevel => {
  return zoomValue < ZoomLevelValues[ZoomLevel.VeryWide].min
    ? ZoomLevel.VeryWide
    : zoomValue >= ZoomLevelValues[ZoomLevel.Wide].max &&
      zoomValue < ZoomLevelValues[ZoomLevel.Wide].min
    ? ZoomLevel.Wide
    : zoomValue >= ZoomLevelValues[ZoomLevel.Medium].max &&
      zoomValue < ZoomLevelValues[ZoomLevel.Medium].min
    ? ZoomLevel.Medium
    : ZoomLevel.Narrow;
};

// Returns true if current zoom value is lower than the specified zoom level.
export const minZoomLevel = (zoomLevel: ZoomLevel, currentZoomValue: number) =>
  currentZoomValue >= ZoomLevelValues[zoomLevel].min;

export const maxZoomLevel = (zoomLevel: ZoomLevel, currentZoomValue: number) =>
  currentZoomValue <= ZoomLevelValues[zoomLevel].max;

export const getStrokeWidthByZoomValue = (zoomValue: number) => {
  const zoom = getZoomLevel(zoomValue);
  if (zoom === ZoomLevel.VeryWide) {
    return 8;
  } else if (zoom === ZoomLevel.Narrow) {
    return 2;
  } else {
    return 4;
  }
};

export const getNodeRiskIndicatorRadiusByZoomValue = (
  zoomValue: number,
  baseRadius: number
): number => {
  const zoom = getZoomLevel(zoomValue);
  if (zoom === ZoomLevel.VeryWide) {
    return baseRadius / 1.6;
  } else if (zoom === ZoomLevel.Wide) {
    return baseRadius / 2.2;
  } else if (zoom === ZoomLevel.Medium) {
    return baseRadius / 3;
  } else {
    return baseRadius / 5;
  }
};

export const getNodeRiskIndicatorOffsetByZoomValue = (
  zoomValue: number,
  initialCoordinate: number
): number => {
  const zoom = getZoomLevel(zoomValue);
  if (zoom === ZoomLevel.VeryWide || zoom === ZoomLevel.Wide) {
    return initialCoordinate;
  } else if (zoom === ZoomLevel.Medium) {
    return initialCoordinate - 6;
  } else {
    return initialCoordinate - 14;
  }
};

export const requiredAwsServiceNamesForGraph = [
  'API Gateway',
  'DynamoDB Table',
  'EC2 Instance',
  'EKS Cluster',
  'ELBV2 Load Balancer',
  'ElastiCache Cluster',
  'Lambda Function',
  'RDS Instance',
  'S3 Bucket',
  'VPC Endpoint',
  'VPC Internet Gateway',
  'VPC NAT Gateway',
  'RDS DB Cluster',
  'Elastic File System',
  'SQS Queue',
  'MSK',
  'IAM',
  'DocumentDB',
];

export const requiredAzureServiceNamesForGraph = [
  'CosmosDB Servers',
  'KeyVault Vault',
  'MySQL Server',
  'Network Load Balancer',
  'Network Private Endpoint',
  'SQL Database',
  'SQL Server',
  'Storage Account',
  'Virtual Machine',
  'Virtual Machine Disk',
  'Network Application Gateway',
];

export const requiredGCPServiceNamesForGraph = [
  'API Gateway Api',
  'CloudSQL Instances',
  'Compute Engine Virtual Machine',
  'Compute Engine Instance Groups',
  'Compute Engine Persistent Disks',
  'CloudLoadBalancing Backend',
  'Cloud Storage Buckets',
  'CloudVPC FirewallRule',
  'Cloud Functions',
  'GKE Cluster',
];

export const requiredProvidersServiceNamesForGraph = {
  AWS: requiredAwsServiceNamesForGraph,
  Azure: requiredAzureServiceNamesForGraph,
  GCP: requiredGCPServiceNamesForGraph,
};

export const getLevelGap = (
  axis: 'x' | 'y',
  gridWidth: number,
  gridHeight: number,
  flipGraphOrientation: boolean
): number => {
  const DEFAULT_LEVEL_GAP = 200;
  const FACTOR = axis === 'x' ? 4 : 10;
  const GRID_SIZE = axis === 'x' ? gridWidth : gridHeight;
  const MAX_LEVEL_GAP = axis === 'x' ? 300 : 500;

  return GRID_SIZE && (axis === 'x' ? !flipGraphOrientation : flipGraphOrientation)
    ? DEFAULT_LEVEL_GAP * (GRID_SIZE / FACTOR >= 1 ? GRID_SIZE / FACTOR : 1) < MAX_LEVEL_GAP
      ? DEFAULT_LEVEL_GAP * (GRID_SIZE / FACTOR >= 1 ? GRID_SIZE / FACTOR : 1)
      : MAX_LEVEL_GAP
    : DEFAULT_LEVEL_GAP;
};

const getInternetNodePosition = (
  grid: SvcRisksApi.Schemas.Grid,
  flipGraphOrientation: boolean,
  gridNodes: GraphNode[]
): number => {
  if (grid?.grid?.length === 0 || gridNodes.length === 0) return 0;
  const idsOfSecondRow = grid.grid[1].filter((id: number) => id !== -1);

  if (!!idsOfSecondRow.length && idsOfSecondRow.length % 2 !== 0) {
    // If grid second row length is odd, we want to align internet with middle element position.
    const middleIndex = Math.floor(idsOfSecondRow.length / 2);
    const middleNode = gridNodes.find(
      (gridNode) => gridNode.groupID === idsOfSecondRow[middleIndex]
    );
    if (!middleNode) return 0;
    return flipGraphOrientation ? middleNode?.x : middleNode?.y;
  } else {
    // If grid second row length is even, we want to align internet with middle two elements positions.
    const firstMiddleNodeId = idsOfSecondRow[idsOfSecondRow.length / 2 - 1];
    const secondMiddleNodeId = idsOfSecondRow[idsOfSecondRow.length / 2];
    const firstMiddleNodeOfSecondRow = gridNodes.find(
      (gridNode) => gridNode.groupID === firstMiddleNodeId
    );
    const secondMiddleNodeOfSecondRow = gridNodes.find(
      (gridNode) => gridNode.groupID === secondMiddleNodeId
    );

    if (!firstMiddleNodeOfSecondRow || !secondMiddleNodeOfSecondRow) {
      return 0;
    } else {
      return flipGraphOrientation
        ? firstMiddleNodeOfSecondRow.x -
            (firstMiddleNodeOfSecondRow.x - secondMiddleNodeOfSecondRow.x) / 2
        : firstMiddleNodeOfSecondRow.y -
            (firstMiddleNodeOfSecondRow.y - secondMiddleNodeOfSecondRow.y) / 2;
    }
  }
};

const getGraphContentHeight = (
  maxNodeYPosPerRow: Record<string, number>,
  maxNodeHeightPerRow: Record<string, number>
) => {
  // take first row Y Pos and add half of its max node height
  return (
    maxNodeYPosPerRow[0] +
    maxNodeHeightPerRow[0] / 2 +
    // Add last row Y pos + half of last row max node height
    maxNodeYPosPerRow[Object.keys(maxNodeYPosPerRow).length - 1] +
    maxNodeHeightPerRow[Object.keys(maxNodeYPosPerRow).length - 1] / 2
  );
};
const getGraphContentWidth = (
  maxNodeXPosPerColumn: Record<string, number>,
  maxNodeWidthPerColumn: Record<string, number>
) => {
  return (
    maxNodeXPosPerColumn[0] +
    maxNodeWidthPerColumn[0] / 2 +
    maxNodeXPosPerColumn[Object.keys(maxNodeXPosPerColumn).length - 1] +
    maxNodeWidthPerColumn[Object.keys(maxNodeWidthPerColumn).length - 1] / 2
  );
};

export const augmentDataForGridGraphViz = (
  flipGraphOrientation: boolean,
  grid: any,
  gridSystem: any,
  gridGraphData: SvcRisksApi.Schemas.ListAssetGroupsByRegionResponse,
  X_GAP: number,
  Y_GAP: number,
  NODE_RADIUS: number
) => {
  const maxNodeXPosPerColumn: Record<string, number> = {};
  const maxNodeYPosPerRow: Record<string, number> = {};
  const maxNodeHeightPerRow: Record<string, number> = {};
  const maxNodeWidthPerColumn: Record<string, number> = {};
  const GRAPH_CONSTANTS = getGraphConstants(NODE_RADIUS);

  let startYPos = 0;
  let isPayloadMalformed: boolean = false;

  if (!Flags.enableVPCView) {
    gridGraphData.groups = gridGraphData.groups.filter((group) => group.serviceType !== 'VPC');
  }

  // Set x,y coordinates on all nodes based on grid placement.
  // looping through each columns.
  const gridNodes: GraphNode[] = [];

  // looping through each cols.
  gridSystem.forEach((columnData: any[], colIndex: number) => {
    // looping through each rows.
    startYPos = 0;

    columnData.forEach((nodeId: number, rowIndex: number) => {
      // -1 means an empty space in the grid, skip this iteration.
      if (nodeId === -1) return;

      const gapBetweenRows = rowIndex === 0 ? 0 : Y_GAP / 2;
      // TODO: Find a better way to type this.
      const currentNode: any = {
        ...gridGraphData.groups.find((group) => group.groupID === nodeId),
      };

      // Make sure the currentNode found within gridGraphData.groups is not an empty object and has an id.
      if (typeof currentNode.groupID === 'number') {
        const filteredChildrenList = currentNode.childrenIDs?.filter((childrenID: number) =>
          gridGraphData.groups.find((group) => group.groupID === childrenID)
        );
        if (
          currentNode.childrenIDs &&
          currentNode.childrenIDs.length !== filteredChildrenList?.length
        ) {
          console.error(
            `Current node ${currentNode.groupID} has children (in it's childrenIDs field) that could not be found in the groups list.`
          );
          isPayloadMalformed = true;
          return;
        }

        const nodeWidth = currentNode.childrenIDs?.length
          ? getGroupNodeWidth(NODE_RADIUS, currentNode.childrenIDs?.length)
          : GRAPH_CONSTANTS.nodeWidth;
        const nodeHeight = currentNode.childrenIDs?.length
          ? getGroupNodeHeight(NODE_RADIUS, currentNode.childrenIDs?.length)
          : GRAPH_CONSTANTS.nodeHeight;

        startYPos =
          currentNode.childrenIDs?.length && currentNode.childrenIDs?.length > 1
            ? startYPos + nodeHeight / 2 + gapBetweenRows
            : startYPos;

        if (currentNode.childrenIDs) {
          // We have a grouping element (parent)
          currentNode.nodeWidth = nodeWidth;
          currentNode.nodeHeight = nodeHeight;

          const currentMaxNodeHeight = Math.max(
            maxNodeHeightPerRow[rowIndex] || nodeHeight,
            nodeHeight
          );
          maxNodeHeightPerRow[rowIndex] = currentMaxNodeHeight;

          const currentMaxNodeWidth = Math.max(
            maxNodeWidthPerColumn[colIndex] || nodeWidth,
            nodeWidth
          );
          maxNodeWidthPerColumn[colIndex] = currentMaxNodeWidth;
        } else {
          // We have a normal group (individual node placed on the grid)
          currentNode.nodeWidth = nodeWidth;
          currentNode.nodeHeight = nodeHeight;
          maxNodeHeightPerRow[rowIndex] = nodeHeight;
          maxNodeWidthPerColumn[colIndex] = nodeWidth;
        }

        currentNode.colIndex = colIndex;
        currentNode.rowIndex = rowIndex;

        gridNodes.push(currentNode);
      } else {
        // We have not find node for groupID
        console.error(`Current node ID ${currentNode.groupID} has no or a non numeric value`);
        isPayloadMalformed = true;
      }
    });
  });

  // If something goes wrong in the grid nodes building, we'll be left with one node only. (internet node)
  if (gridNodes.length < 2) {
    console.error(
      'Following payload data transformation, there is insufficient nodes to render the visualization'
    );
    isPayloadMalformed = true;
  }

  // Setting maximum node height per rows to center all items within each rows.
  Object.keys(maxNodeHeightPerRow).forEach((rowIndex) => {
    if (rowIndex === '0') {
      maxNodeYPosPerRow[rowIndex] = 0;
    } else {
      maxNodeYPosPerRow[rowIndex] =
        maxNodeYPosPerRow[parseInt(rowIndex) - 1] +
        Y_GAP +
        (maxNodeHeightPerRow[rowIndex] / 2 > Y_GAP
          ? maxNodeHeightPerRow[rowIndex] / 2 + Y_GAP / 2
          : Y_GAP);
    }
  });

  // Setting maximum node width per columns to center all items within each columns.
  Object.keys(maxNodeWidthPerColumn).forEach((colIndex) => {
    if (colIndex === '0') {
      maxNodeXPosPerColumn[colIndex] = 0;
    } else {
      maxNodeXPosPerColumn[colIndex] =
        // Start with previous col maxXPos
        maxNodeXPosPerColumn[parseInt(colIndex) - 1] + // (we're at center of previous col)
        // Add previous col max node width / 2
        maxNodeWidthPerColumn[parseInt(colIndex) - 1] / 2 + // (we're at the end of the last col)
        // Add gap between col
        X_GAP + // (we're at start of current col)
        // Add half of width of current col max node width
        maxNodeWidthPerColumn[colIndex] / 2; // (we're at middle of current col)
    }
  });

  const graphContentHeight = getGraphContentHeight(maxNodeYPosPerRow, maxNodeHeightPerRow);
  const graphContentWidth = getGraphContentWidth(maxNodeXPosPerColumn, maxNodeWidthPerColumn);
  const graphContentStartXPos = maxNodeYPosPerRow[0] - maxNodeWidthPerColumn[0] / 2;
  const graphContentStartYPos = maxNodeXPosPerColumn[0] - maxNodeHeightPerRow[0] / 2;

  gridNodes.forEach((group) => {
    group.x = group.groupLabel === 'Internet' ? 0 : maxNodeXPosPerColumn[group.colIndex];
    group.y = group.groupLabel === 'Internet' ? 0 : maxNodeYPosPerRow[group.rowIndex];
  });

  // Set Internet node coordinates.
  const internetNode = gridNodes.find((node) => node.groupLabel === 'Internet');
  const internetPosition = getInternetNodePosition(grid, flipGraphOrientation, gridNodes);
  if (internetNode) {
    internetNode.x = flipGraphOrientation ? internetPosition : 0;
    internetNode.y = flipGraphOrientation ? 0 : internetPosition;
  }

  // Set SecurityGroup node coordinates.
  const securityGroupNode = gridNodes.find((node) =>
    securityGroupServiceTypes.includes(node.serviceType)
  );
  if (securityGroupNode) {
    if (flipGraphOrientation) {
      securityGroupNode.x = internetPosition;
      securityGroupNode.y = securityGroupNode.y;
    } else {
      securityGroupNode.x = securityGroupNode.x;
      securityGroupNode.y = internetPosition;
    }
  }

  const childNodesDict: Record<string, any[]> = {}; // pairing of nodeID: array of childs
  gridGraphData.groups.forEach((currentNode: SvcRisksApi.Schemas.AssetGroup) => {
    if (currentNode.childrenIDs) {
      childNodesDict[currentNode.groupID] = gridGraphData.groups
        .filter((node) => currentNode?.childrenIDs?.includes(node.groupID))
        .map((childNode, index) => {
          const parentNode = gridNodes.find((node) => node.groupID === currentNode.groupID);

          const filteredChildrenList = parentNode?.childrenIDs?.filter((childrenID: number) =>
            gridGraphData.groups.find((group) => group.groupID === childrenID)
          );

          if (!parentNode?.childrenIDs) return;
          if (parentNode?.childrenIDs?.length !== filteredChildrenList?.length) {
            console.error(
              `Current parentNode ${parentNode.groupID} has children (in it's childrenIDs field) that could not be found in the groups list.`
            );
            isPayloadMalformed = true;
            return;
          }

          const nodeCol = (index % GRAPH_CONSTANTS.itemsPerColumn) + 1;
          const nodeRow = Math.ceil((index + 1) / GRAPH_CONSTANTS.itemsPerColumn);

          const nodeX =
            // Start with the parent X (middle of grouping rect)
            parentNode?.x -
            // Subtract half of grouping rect width (gives start of the grouping rect)
            getGroupNodeWidth(NODE_RADIUS, parentNode?.childrenIDs?.length) / 2 +
            // Add grouping rect left side padding
            GRAPH_CONSTANTS.groupNodePadding +
            // Add half of node width
            GRAPH_CONSTANTS.nodeWidth / 2 +
            // Add node width for each subsequent rows
            GRAPH_CONSTANTS.nodeWidth * (nodeRow - 1);

          const nodeY =
            parentNode?.y -
            getGroupNodeHeight(NODE_RADIUS, parentNode?.childrenIDs?.length) / 2 +
            GRAPH_CONSTANTS.groupNodePadding +
            (GRAPH_CONSTANTS.nodeHeight / 2) * nodeCol +
            NODE_RADIUS * 2 * (nodeCol - 1) -
            nodeLabelTextHeight / 2;

          return {
            ...childNode,
            x: nodeX,
            y: nodeY,
            rowIndex: nodeRow,
            colIndex: nodeCol,
            nodeWidth: GRAPH_CONSTANTS.nodeWidth,
            nodeHeight: GRAPH_CONSTANTS.nodeHeight,
          } as GraphNode;
        });
    }
  });

  const childrenNodesIds = gridGraphData.groups.flatMap((group) => group.childrenIDs ?? []);

  // Augmenting nodes to help with graph layout.
  const d3nodes: GraphNode[] = gridNodes
    .map((d) => ({
      ...d,
      ...(childNodesDict[d.groupID] && { childNodes: childNodesDict[d.groupID] }),
      ...(d.nodeWidth && { nodeWidth: d.nodeWidth }),
    }))
    .filter((d3node) => !childrenNodesIds.includes(d3node.groupID));

  return {
    isPayloadMalformed: isPayloadMalformed,
    graphContentHeight: graphContentHeight,
    graphContentWidth: graphContentWidth,
    graphContentStartXPos: graphContentStartXPos,
    graphContentStartYPos: graphContentStartYPos,
    nodes: d3nodes,
  };
};

export const getGridElementLevelByGroupId = (
  id: number,
  gridSystem: GraphGrid,
  graphOrientation: GraphOrientation
): number => {
  let level: number = 0;

  gridSystem.forEach((rows, colIndex) => {
    rows.forEach((groupId: number, rowIndex) => {
      if (id === groupId) {
        level = graphOrientation === 'horizontal' ? colIndex : rowIndex;
        return;
      }
    });
  });

  return level;
};

export const getNodeLabelHeight = (zoomLevel: number) => {
  return minZoomLevel(ZoomLevel.Wide, zoomLevel) ? nodeLabelTextHeight : 0;
};

export const getParentNodeLabelHeight = (zoomLevel: number) => {
  return minZoomLevel(ZoomLevel.Medium, zoomLevel) ? nodeLabelTextHeight : nodeLabelTextHeight + 20;
};
