const getFlagValue = (flagName: string): boolean => {
  const storageFlag = localStorage.getItem(flagName);
  // always enable flags in development and staging when flags are missing
  if (storageFlag === null && ['development', 'alpha', 'staging'].includes(import.meta.env.MODE)) {
    return true;
  }
  try {
    return Boolean(JSON.parse(storageFlag || 'false'));
  } catch {
    console.warn(`Unexpected local storage value for ${flagName}`);
    return false;
  }
};

export const Flags = {
  enableVPCView: getFlagValue('REX_enableVPCView'),
};
