import { createContext, useContext } from 'react';

type CloudAssetsGraphContextValue = {
  contextMenuNodeId?: number;
  activeGroupId?: number;
  activeGroupParentId?: number;
  securityGroupNodeIsActive?: boolean;
  securityGroupNodeId?: number | undefined;
  hoveredGroupId?: number;
  activeSecurityGroupAssetCount?: number | undefined;
  activeSecondaryLinks?: SvcRisksApi.Schemas.Link[];
  activePrimaryLinks?: SvcRisksApi.Schemas.Link[];
  activeSGLinks?: SvcRisksApi.Schemas.Link[];
  isolatedNodeIsActive?: boolean;
  onContextMenuNodeChange: (id?: number) => void;
  onActiveGroupIdChange: (id?: number) => void;
  onSecurityGroupNodeIdChange: (id?: number | undefined) => void;
  onActiveGroupParentIdChange: (id?: number) => void;
  onHoveredGroupIdChange: (id?: number) => void;
  onSecurityGroupNodeIsActiveChange: (active: boolean) => void;
  onActiveSecurityGroupAssetCountChange: (count?: number | undefined) => void;
  onActiveSecondaryLinksChange: (linkIdList?: SvcRisksApi.Schemas.Link[]) => void;
  onActivePrimaryLinksChange: (linkIdList?: SvcRisksApi.Schemas.Link[]) => void;
  onActiveSGLinksChange: (linkIdList?: SvcRisksApi.Schemas.Link[]) => void;
  onIsolatedNodeIsActiveChange: (active: boolean) => void;
};
export const CloudAssetsGraphContext = createContext<CloudAssetsGraphContextValue>(
  undefined as never
);

export const useCloudAssetsGraphContext = () => {
  return useContext(CloudAssetsGraphContext);
};
