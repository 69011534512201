import { PropsWithChildren } from 'react';
import { Menu, MenuDivider, MenuItem, Text, Tooltip, useTheme, Flex } from '@tonic-ui/react';
import { useTranslation } from 'react-i18next';
import NodeTooltipNumberedItem from './NodeTooltipNumberedItem';
import { tooltipBGColorCode } from '../helpers';
import { getRiskColor } from '../../helpers';

type NodePopoverProps = {
  isShown: boolean;
  label: string;
  serviceName?: string;
  serviceType: string;
  riskiestAssetScore?: number;
  highRiskAssetCount?: number;
};

const NodePopover = (props: PropsWithChildren<NodePopoverProps>) => {
  const { isShown, label, serviceType, riskiestAssetScore, highRiskAssetCount, children } = props;
  const theme = useTheme();
  const { colors } = theme;
  const { t } = useTranslation();

  return (
    <Tooltip
      isOpen={isShown}
      background={colors[tooltipBGColorCode]}
      padding={0}
      label={
        <Menu display="block" padding="0">
          <MenuItem>
            <Flex flexDirection="column">
              <Text color="white:emphasis" fontWeight="bold">
                {label}
              </Text>
              {label !== serviceType && (
                <Text color="white:secondary" fontSize="small">
                  {serviceType}
                </Text>
              )}
            </Flex>
          </MenuItem>
          <MenuDivider marginY="0" marginX="2x" />
          {riskiestAssetScore !== undefined && (
            <MenuItem color="white:secondary">
              <NodeTooltipNumberedItem
                labelTransKey="riskGraph.groupNodeTooltip.highestRiskScore"
                value={riskiestAssetScore === 0 ? t('notAvailableShort') : riskiestAssetScore}
                valueTextColor={getRiskColor(riskiestAssetScore)}
              />
            </MenuItem>
          )}
          {highRiskAssetCount !== undefined && (
            <MenuItem color="white:secondary">
              <NodeTooltipNumberedItem
                labelTransKey="riskyAssetCount"
                value={highRiskAssetCount}
                valueTextColor={getRiskColor(highRiskAssetCount)}
              />
            </MenuItem>
          )}
        </Menu>
      }
      placement="right"
      PopperProps={{ usePortal: true, zIndex: 10 }}
    >
      {children}
    </Tooltip>
  );
};

NodePopover.displayName = 'NodePopover';
export default NodePopover;
