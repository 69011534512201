export const assetsDrawerWidth: number = 500;

export const getRiskLevel = (
  riskScore: number | undefined
): 'default' | 'low' | 'medium' | 'high' => {
  if (riskScore && (riskScore < 0 || riskScore > 100)) {
    console.warn(`Risk score is out of range: ${riskScore}`);
  }
  if (riskScore === undefined) {
    return 'default';
  } else if (riskScore < 31) {
    return 'low';
  } else if (riskScore < 70) {
    return 'medium';
  } else {
    return 'high';
  }
};

export const getRiskColor = (riskScore: number | undefined): string => {
  return {
    low: 'gray:40',
    medium: 'yellow:50',
    high: 'red:50',
    default: 'gray:40',
  }[getRiskLevel(riskScore)];
};

export const getAccountsRiskColor = (riskScore: number | undefined) => {
  return {
    low: 'green:40',
    medium: 'yellow:50',
    high: 'red:50',
    default: 'green:40',
  }[getRiskLevel(riskScore)];
};

export const getEdgeLabelTransKey = (linkType: string) => {
  return (
    {
      ROUTESTO: 'edgesLabels.routesToLabel',
      OPENACCESS: 'edgesLabels.openAccessLabel',
      CONNECTS: 'edgesLabels.connectsLabel',
    }[linkType] ?? linkType
  );
};

export const securityGroupServiceTypes = [
  'SG',
  'Microsoft.Network/networkSecurityGroups',
  'CloudVPC FirewallRule',
];
