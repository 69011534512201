import { useMemo, useCallback } from 'react';
import { linkHorizontal, linkVertical, path } from 'd3';
import { useTheme } from '@tonic-ui/react';
import {
  findRelatedEdgesByGroupId,
  getCustomPathPoints,
  getLinkXDirection,
  getLinkYDirection,
  getNodeLinkTouchPoint,
  getNodeTouchPointCoordinates,
  findEdgeInList,
  getLinkOffset,
  getEdgeTargetOffsetMultiplierBasedOnSource,
} from './linkHelpers';
import CloudAssetsGraphLinkLabel from './CloudAssetsGraphLinkLabel';
import { useCloudAssetsGraphContext } from '../useCloudAssetsGraphContext';

export type CloudAssetsGraphLinkProps = {
  graphOrientation: 'horizontal' | 'vertical';
  strokeWidth?: number;
  link: GraphLink;
  grid: GraphGrid;
  X_GAP: number;
  Y_GAP: number;
  zoomLevel: number;
  primaryLinkList: SvcRisksApi.Schemas.Link[];
  secondaryLinkList?: SvcRisksApi.Schemas.Link[];
  isLinkSecondary?: boolean;
  isLinkSecurityGroup?: boolean;
  nodeRadius: number;
};

const LABEL_HEIGHT = 32;
const LABEL_WIDTH = 32;

const CloudAssetsGraphLink = ({
  strokeWidth = 2,
  graphOrientation,
  link,
  grid,
  X_GAP,
  Y_GAP,
  zoomLevel,
  primaryLinkList,
  secondaryLinkList,
  isLinkSecondary = false,
  isLinkSecurityGroup = false,
  nodeRadius,
}: CloudAssetsGraphLinkProps) => {
  const { source, target, linkDirection, linkType, assetSecurityGroups } = link;

  const theme = useTheme();

  const isOpenAccess = linkType === 'OPENACCESS';

  const edgeColors: Record<string, string> = {
    active: theme.colors['blue:50'],
    activeMain: theme.colors['white:primary'],
    dimmed: theme.colors['gray:90'],
    default: theme.colors['gray:60'],
    risky: theme.colors['red:50'],
    riskyDimmed: theme.colors['red:50'],
  };

  const getEdgeColorKey = useCallback(
    (
      currentEdgeIsInRelatedActiveList: boolean,
      currentEdgeIsInRelatedHoveredList: boolean,
      isAnyActiveSecondaryLinks: boolean,
      isAnyDimmedGroup: boolean,
      isLinkDuplicateOfActiveSGLink: boolean
    ) => {
      if (
        (currentEdgeIsInRelatedActiveList && !isLinkDuplicateOfActiveSGLink) ||
        currentEdgeIsInRelatedHoveredList ||
        (isOpenAccess && !isAnyDimmedGroup) ||
        isLinkSecurityGroup
      ) {
        // Full opacity colors.
        if (isOpenAccess) {
          return 'risky';
        } else if (isLinkSecondary) {
          return 'active';
        } else if (currentEdgeIsInRelatedActiveList) {
          return isAnyActiveSecondaryLinks ? 'activeMain' : 'active';
        } else if (currentEdgeIsInRelatedHoveredList) {
          return 'activeMain';
        } else {
          return 'default';
        }
      } else if (isAnyDimmedGroup) {
        // Dimmed opacity colors.
        return isOpenAccess ? 'riskyDimmed' : 'dimmed';
      } else if (isOpenAccess) {
        return 'risky';
      } else {
        // Default opacity colors.
        return 'default';
      }
    },
    [isOpenAccess, isLinkSecondary, isLinkSecurityGroup]
  );

  const {
    activeGroupId,
    activeGroupParentId,
    securityGroupNodeId,
    hoveredGroupId,
    activeSecondaryLinks,
    activeSGLinks,
    isolatedNodeIsActive,
    onSecurityGroupNodeIsActiveChange,
  } = useCloudAssetsGraphContext();

  const isLinkDuplicateOfActiveSGLink = useMemo(() => {
    return !!(
      link.target.groupID === activeGroupParentId &&
      activeSGLinks?.some((activeSGLink) => activeSGLink.targetGroupID === activeGroupId)
    );
  }, [link, activeGroupId, activeGroupParentId, activeSGLinks]);

  // link state and visual properties
  let edgeColor: string;
  let edgeColorKey: string;
  let edgeOpacity = 1;
  let edgeIsSelected = false;
  let currentEdgeIsShown = false;

  if (!activeGroupId && !activeGroupParentId && !hoveredGroupId && !activeSecondaryLinks) {
    edgeColorKey = isOpenAccess ? 'risky' : 'default';
    edgeColor = isOpenAccess ? edgeColors.risky : edgeColors.default;
    edgeIsSelected = false;
  } else {
    const activeResourceId = activeGroupParentId || activeGroupId;
    const isAnyDimmedGroup =
      !isolatedNodeIsActive && !!(hoveredGroupId || activeGroupId || activeGroupParentId);

    let currentEdgeIsInRelatedActiveList = false;
    let currentEdgeIsInRelatedHoveredList = false;

    if (activeResourceId) {
      const relatedActiveEdges = findRelatedEdgesByGroupId(activeResourceId, primaryLinkList);
      currentEdgeIsInRelatedActiveList = findEdgeInList(link, relatedActiveEdges);

      if (activeGroupId && !!activeSecondaryLinks?.length && !!secondaryLinkList?.length) {
        currentEdgeIsInRelatedActiveList = findEdgeInList(link, activeSecondaryLinks);
      }

      if (isLinkSecurityGroup && activeGroupId === securityGroupNodeId) {
        // We show all links related to SG node.
        currentEdgeIsShown =
          link.source.groupID === activeGroupId || link.target.groupID === activeGroupId;
        currentEdgeIsInRelatedActiveList = currentEdgeIsShown;
      } else if (
        isLinkSecurityGroup &&
        activeGroupId &&
        securityGroupNodeId &&
        activeGroupId !== securityGroupNodeId
      ) {
        // We show SG links related to activeGroupId node.
        currentEdgeIsShown =
          link.target.groupID === activeGroupId ||
          (link.target.groupID === securityGroupNodeId &&
            !!link.endTargetGroupIDs?.length &&
            link.endTargetGroupIDs.includes(activeGroupId));
        currentEdgeIsInRelatedActiveList = currentEdgeIsShown;

        if (currentEdgeIsShown) {
          onSecurityGroupNodeIsActiveChange(true);
        }
      }
    }

    if (hoveredGroupId && !currentEdgeIsInRelatedActiveList) {
      const relatedHoveredEdges = findRelatedEdgesByGroupId(hoveredGroupId, primaryLinkList);
      currentEdgeIsInRelatedHoveredList = findEdgeInList(link, relatedHoveredEdges);
    }

    edgeIsSelected = currentEdgeIsInRelatedActiveList;
    edgeColorKey = getEdgeColorKey(
      currentEdgeIsInRelatedActiveList,
      currentEdgeIsInRelatedHoveredList,
      !!activeSecondaryLinks?.length,
      isAnyDimmedGroup,
      isLinkDuplicateOfActiveSGLink
    );
    edgeColor = edgeColors[edgeColorKey];
    edgeOpacity = edgeColorKey === 'riskyDimmed' ? 0.2 : 1;
  }

  // link curve and anchor points
  const isLinkInsideSameParent = isLinkSecondary && source.parentID === target.parentID;
  const isLinkOnXSameLevel = source.x === target.x;
  const isLinkOnYSameLevel = source.y === target.y;
  const linkXDirection = getLinkXDirection(isLinkOnXSameLevel, source.x, target.x);
  const linkYDirection = getLinkYDirection(isLinkOnYSameLevel, source.y, target.y);

  const isLinkBetweenSubnets = !!(
    link.source.childrenIDs?.length && link.target.childrenIDs?.length
  );
  const sourceGroupGridHop = grid.findIndex((hop) => hop.includes(link.source.groupID));
  const targetGroupGridHop = grid.findIndex((hop) => hop.includes(link.target.groupID));
  const linkIsBetweenSiblingSubnets = !!(
    isLinkBetweenSubnets &&
    sourceGroupGridHop !== -1 &&
    targetGroupGridHop !== -1 &&
    sourceGroupGridHop === targetGroupGridHop &&
    Math.abs(
      grid[sourceGroupGridHop].indexOf(link.source.groupID) -
        grid[targetGroupGridHop].indexOf(link.target.groupID)
    ) === 1
  );

  const sourceNodeLinkTouchPoint = getNodeLinkTouchPoint(
    isLinkSecondary,
    linkIsBetweenSiblingSubnets,
    'source',
    graphOrientation,
    isLinkOnXSameLevel,
    isLinkOnYSameLevel,
    linkXDirection,
    linkYDirection,
    isLinkInsideSameParent,
    source,
    target
  );
  const targetNodeLinkTouchPoint = getNodeLinkTouchPoint(
    isLinkSecondary,
    linkIsBetweenSiblingSubnets,
    'target',
    graphOrientation,
    isLinkOnXSameLevel,
    isLinkOnYSameLevel,
    linkXDirection,
    linkYDirection,
    isLinkInsideSameParent,
    source,
    target
  );

  const sourceOffset =
    source.groupID === 0
      ? nodeRadius
      : getLinkOffset(source, graphOrientation, nodeRadius, sourceNodeLinkTouchPoint, zoomLevel);

  const offsetMultiplierBasedOnSource = useMemo(
    () =>
      !!target.childNodes
        ? getEdgeTargetOffsetMultiplierBasedOnSource(
            grid,
            source.groupID,
            target.groupID,
            primaryLinkList
          )
        : 0,
    [grid, primaryLinkList, source, target]
  );

  const targetBaseOffset = 4; // Adds space to avoid link end touching the node element.
  const targetArrowHeadSize = 10; // Allows arrow head to stop directly before arrow head markerEnd.
  const targetOffset =
    getLinkOffset(target, graphOrientation, nodeRadius, targetNodeLinkTouchPoint, zoomLevel) +
    targetBaseOffset +
    targetArrowHeadSize;

  const sourceTouchPointsCoordinates =
    (isLinkSecondary && !source.childNodes) || linkIsBetweenSiblingSubnets
      ? getNodeTouchPointCoordinates(source, sourceNodeLinkTouchPoint, sourceOffset)
      : {
          x:
            graphOrientation === 'horizontal'
              ? linkDirection === 'backwards'
                ? source.x - sourceOffset
                : source.x + sourceOffset
              : source.x,
          y:
            graphOrientation === 'horizontal'
              ? source.y
              : linkDirection === 'backwards'
              ? source.y - sourceOffset
              : source.y + sourceOffset,
        };

  const targetTouchPointsCoordinates =
    (isLinkSecondary && !source.childNodes) || linkIsBetweenSiblingSubnets
      ? getNodeTouchPointCoordinates(target, targetNodeLinkTouchPoint, targetOffset)
      : {
          x:
            graphOrientation === 'horizontal'
              ? source.x === target.x || linkDirection === 'backwards'
                ? target.x + targetOffset
                : target.x - targetOffset
              : target.x +
                (isOpenAccess ? LABEL_WIDTH : targetArrowHeadSize * 2) *
                  offsetMultiplierBasedOnSource,
          y:
            graphOrientation === 'horizontal'
              ? target.y +
                (isOpenAccess ? LABEL_WIDTH : targetArrowHeadSize * 2) *
                  offsetMultiplierBasedOnSource
              : source.y === target.y || linkDirection === 'backwards'
              ? target.y + targetOffset
              : target.y - targetOffset,
        };

  const isHorizontalTouchPoint = ['left', 'right'].includes(targetNodeLinkTouchPoint);
  const isVerticalTouchPointInVerticalGraph =
    ['bottom', 'top'].includes(targetNodeLinkTouchPoint) && graphOrientation === 'vertical';
  const linkOrientation =
    isHorizontalTouchPoint || isVerticalTouchPointInVerticalGraph ? 'horizontal' : 'vertical';

  const linkIsOnSameLevel = isLinkInsideSameParent
    ? sourceNodeLinkTouchPoint === targetNodeLinkTouchPoint
    : isLinkSecondary
    ? source.x === target.x || source.y === target.y
    : graphOrientation === 'horizontal'
    ? isLinkOnXSameLevel
    : isLinkOnYSameLevel;

  const edgePath = useMemo(() => {
    if (linkIsOnSameLevel && !linkIsBetweenSiblingSubnets) {
      const context = path();
      const { startPoint, startMiddlePoint, endMiddlePoint, endPoint } = getCustomPathPoints(
        isLinkSecondary,
        sourceTouchPointsCoordinates,
        targetTouchPointsCoordinates,
        sourceOffset,
        targetOffset,
        graphOrientation === 'horizontal',
        LABEL_WIDTH,
        LABEL_HEIGHT,
        isLinkOnXSameLevel,
        linkXDirection,
        linkYDirection,
        sourceNodeLinkTouchPoint,
        targetNodeLinkTouchPoint,
        X_GAP,
        Y_GAP
      );
      context.moveTo(startPoint.x, startPoint.y);
      context.bezierCurveTo(
        startMiddlePoint.x,
        startMiddlePoint.y,
        endMiddlePoint.x,
        endMiddlePoint.y,
        endPoint.x,
        endPoint.y
      );

      return context.toString();
    }
    const isLinkHorizontal = isLinkSecondary
      ? linkOrientation === 'horizontal'
      : graphOrientation === 'horizontal';
    const linkCurve = isLinkHorizontal ? linkHorizontal : linkVertical;
    const d3Link = linkCurve()
      .x((d: any) => d[0])
      .y((d: any) => d[1]);

    return (
      d3Link({
        source: [sourceTouchPointsCoordinates.x, sourceTouchPointsCoordinates.y],
        target: [targetTouchPointsCoordinates.x, targetTouchPointsCoordinates.y],
      }) ?? ''
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link, sourceTouchPointsCoordinates, targetTouchPointsCoordinates]);

  // gets the closer point to the center of the path
  const getLabelPositionCoordinates = useCallback(() => {
    const pathElement = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    pathElement.setAttribute('d', edgePath);
    const totalLength = pathElement.getTotalLength();

    return pathElement.getPointAtLength(
      totalLength - (graphOrientation === 'horizontal' ? X_GAP / 4 : Y_GAP / 4)
    );
  }, [edgePath, X_GAP, Y_GAP, graphOrientation]);

  const securityGroupsLabelAndPopOver = useMemo(() => {
    if (!isOpenAccess) {
      return <></>;
    } else {
      const labelPositionCoordinates = getLabelPositionCoordinates();
      if (!labelPositionCoordinates) {
        return <></>;
      } else {
        return (
          <CloudAssetsGraphLinkLabel
            assetSecurityGroups={assetSecurityGroups}
            labelPositionCoordinates={labelPositionCoordinates}
            labelWidth={LABEL_WIDTH}
            labelHeight={LABEL_HEIGHT}
            relatedEdgeIsDimmed={edgeColorKey === 'riskyDimmed'}
          />
        );
      }
    }
  }, [getLabelPositionCoordinates, assetSecurityGroups, edgeColorKey, isOpenAccess]);

  if (isLinkSecondary && !edgeIsSelected) return <></>;
  if (isLinkSecurityGroup && !currentEdgeIsShown) return <></>;

  return (
    <g>
      <>
        <defs>
          {Object.entries(edgeColors).map(([colorName, colorValue]) => {
            return (
              <marker
                key={colorName}
                id={colorName}
                viewBox={`0 0 ${targetArrowHeadSize} ${targetArrowHeadSize}`}
                refX="0"
                refY="0"
                orient={linkDirection === 'backwards' ? '-180deg' : 'auto'}
                markerWidth={targetArrowHeadSize}
                markerHeight={targetArrowHeadSize}
                overflow="visible"
                strokeLinecap="round"
                strokeLinejoin="round"
                markerUnits="userSpaceOnUse"
              >
                <path
                  d={`M0,-${targetArrowHeadSize}L${targetArrowHeadSize},0L0,${targetArrowHeadSize}`}
                  fill={colorValue}
                  fillOpacity={colorName === 'riskyDimmed' ? 0.2 : 1}
                ></path>
              </marker>
            );
          })}
        </defs>
        <path
          className={`node-links source_${source.groupID} target_${target.groupID}`}
          id={`source:${source.groupID} -> target${target.groupID}`}
          fill="none"
          stroke={edgeColor}
          strokeWidth={strokeWidth}
          strokeOpacity={edgeOpacity}
          style={{ transition: 'stroke-opacity 0.2s, stroke 0.2s' }}
          data-linktype={linkType}
          d={edgePath}
          markerEnd={`url(#${edgeColorKey})`}
        ></path>
      </>

      {isOpenAccess && securityGroupsLabelAndPopOver}
    </g>
  );
};

CloudAssetsGraphLink.displayName = 'CloudAssetsGraphLink';
export default CloudAssetsGraphLink;
