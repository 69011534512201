import { CloudAccountInfo } from '../views/RiskExplorer';

export interface AppStateProps {
  availableProviders: SvcRisksApi.Schemas.CloudProvider[];
  selectedProvider: SvcRisksApi.Parameters.Provider | 'All' | undefined;
  selectedAccount: CloudAccountInfo | undefined;
  isGraphFullscreen: boolean;
  isRiskFocusViewActive: boolean;
  isGraphLoading?: boolean;
}

export type AppActionsProps =
  | { type: 'SET_PROVIDERS'; payload: SvcRisksApi.Schemas.CloudProvidersResponse }
  | { type: 'CHANGE_CLOUD_PROVIDER'; payload: SvcRisksApi.Parameters.Provider }
  | { type: 'CHANGE_ACCOUNT'; payload: CloudAccountInfo | Partial<CloudAccountInfo> }
  | { type: 'CHANGE_ACCOUNT_REGION'; payload: string }
  | { type: 'CHANGE_ACCOUNT_LAST_UPDATED_TIME'; payload?: string }
  | { type: 'SET_FULLSCREEN'; payload: boolean }
  | { type: 'SET_RISK_FOCUSED_VIEW'; payload: boolean }
  | { type: 'SET_GRAPH_LOADING'; payload?: boolean };

const LOCAL_STORAGE_KEY = 'REX_appState';
const saveState = (state: AppStateProps) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(LOCAL_STORAGE_KEY, serializedState);
  } catch (err) {
    console.error('Could not save state to localStorage:', err);
  }
};
export const loadState = (): AppStateProps | undefined => {
  try {
    const serializedState = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (serializedState === null) {
      return undefined; // No state in localStorage, returning undefined = using initial state
    }
    return JSON.parse(serializedState) as AppStateProps;
  } catch (err) {
    console.error('Could not load state from localStorage:', err);
    return undefined;
  }
};

const AppReducer = (state: AppStateProps, action: AppActionsProps): AppStateProps => {
  let nextState: AppStateProps = { ...state };
  switch (action.type) {
    case 'SET_PROVIDERS':
      nextState = { ...nextState, availableProviders: [...action.payload] };
      break;
    case 'CHANGE_CLOUD_PROVIDER':
      nextState = { ...nextState, selectedProvider: action.payload, selectedAccount: undefined };
      break;
    case 'CHANGE_ACCOUNT':
      nextState = {
        ...nextState,
        selectedAccount: { ...(nextState.selectedAccount as CloudAccountInfo), ...action.payload },
      };
      break;
    case 'CHANGE_ACCOUNT_REGION':
      nextState = {
        ...nextState,
        selectedAccount: {
          ...(nextState.selectedAccount as CloudAccountInfo),
          accountRegion: action.payload,
        },
      };
      break;
    case 'CHANGE_ACCOUNT_LAST_UPDATED_TIME':
      nextState = {
        ...nextState,
        selectedAccount: {
          ...(nextState.selectedAccount as CloudAccountInfo),
          accountLastUpdatedTime: action.payload,
        },
      };
      break;
    case 'SET_FULLSCREEN':
      nextState = { ...nextState, isGraphFullscreen: action.payload };
      break;
    case 'SET_RISK_FOCUSED_VIEW':
      nextState = { ...nextState, isRiskFocusViewActive: action.payload };
      break;
    case 'SET_GRAPH_LOADING':
      nextState = { ...nextState, isGraphLoading: action.payload };
      break;
    default:
      break;
  }
  saveState(nextState);
  return nextState;
};

export default AppReducer;
